import sass from '../scss/main.scss';
import typedtext from './slider-words';
import {event, toggleClass} from './functions.js';
import Inputmask from "inputmask";
import './map';

 // core version + navigation, pagination modules:
 import Swiper, { Navigation, Pagination } from 'swiper/core';
 // configure Swiper to use modules
 Swiper.use([Navigation, Pagination]);

 import Tabby from 'tabbyjs';

// Global
const pageWrapper = document.querySelector(".wrapper");

// Detect window scroll
const detectScroll = () => {
    let isScroll = false;
    if (window.scrollY > 0) {
        isScroll = true;
        pageWrapper.classList.add("scrolled");
    } else {
        isScroll = false;
        pageWrapper.classList.remove("scrolled");
    }
};
detectScroll();
window.addEventListener("scroll", detectScroll);

// Menu
const menuOpenBtn = document.querySelector(".menu-btn");
const menuCloseBtn = document.querySelector(".menu__close");
const menuLayout = document.querySelector(".menu__layout");
const menuScrollBtn = document.querySelector(".menu__container .btn--circle");

menuOpenBtn.addEventListener("click", () => {
    pageWrapper.classList.add("menu-opened");
});
menuCloseBtn.addEventListener("click", () => {
    pageWrapper.classList.remove("menu-opened");
});
menuLayout.addEventListener("click", () => {
    pageWrapper.classList.remove("menu-opened");
});
menuScrollBtn.addEventListener("click", () => {
  pageWrapper.classList.remove("menu-opened");
});


// Cases slider
const swiper = new Swiper('.cases__slider', {
  slidesPerView: 'auto',
  spaceBetween: 27,
  breakpoints: {
    0: {
      spaceBetween: 7
    },
    768: {
      spaceBetween: 27
    }
  },
  pagination: {
    el: '.cases__pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

// Companies slider
const swiperCompany = new Swiper('.goodcompany__slider', {
  slidesPerView: 4,
  breakpoints: {
    0: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 4,
    }
  },
  pagination: {
    el: '.goodcompany__pagination',
    clickable: true
  }
});

// Articles slider
const swiperArticles = new Swiper('.single-article__slider .swiper-container', {
  slidesPerView: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

// Cases tabs
document.querySelectorAll('[data-tabs]').forEach((el) => {
  new Tabby('#' + el.id);
});

// Show all cases
event('.looking-cases__button', 'click', function (e) {
  toggleClass(this.parentElement, 'show-all');
});
// Show all tabs
event('.looking-cases__tab-more', 'click', function (e) {
  toggleClass(this.parentElement, 'show-all');
});

//Маска для полей с телефоном
document.querySelectorAll('input[type="tel"]').forEach(el => {
  Inputmask({
      "mask": "+7 999 999 9999",
      "clearIncomplete": true,
      "placeholder": ""
  }).mask(el);
});

// Number counter
function animateValue(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

const statisticsBlock = document.querySelector(".statistics");
const counterDays = document.querySelector(".statistics__counter--days");
const counterPayment = document.querySelector(".statistics__counter--payment");
const counterWeeks = document.querySelector(".statistics__counter--weeks");
const counterSubWeeks = document.querySelector(".statistics__counter--subweeks");

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const startCounter = () => {
  if (isInViewport(statisticsBlock) && !statisticsBlock.classList.contains("run-counter")) {
    animateValue(counterDays, 0, 1, 3000);
    animateValue(counterPayment, 0, 15, 3000);
    animateValue(counterWeeks, 0, 2, 3000);
    animateValue(counterSubWeeks, 0, 5, 3000);
    statisticsBlock.classList.add("run-counter");
  }
}

startCounter();

document.addEventListener('scroll', function () {
  startCounter();
}, {
  passive: true
});





